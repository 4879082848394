@atom: ListActionButton;

.@{atom} {
    font-size: initial;

    .IconButton:focus ~ .ListAction .ListAction__list,
    .ListAction .ListAction__list:hover {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in, z-index 0.01ms;
    }

    .IconButton:focus ~ .ListAction .ListAction__list.ListAction__list--top,
    .ListAction .ListAction__list.ListAction__list--top:hover {
        transform: translateY(calc(-100% - 60px));
    }
}
